var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-6" }, [
    _c("div", { staticClass: "registry-item" }, [
      _c(
        "div",
        { staticClass: "registry-item__text" },
        [
          _c("h3", { staticClass: "registry-item__title" }, [
            _vm._v(_vm._s(_vm.card.PersonProfile_title))
          ]),
          _vm._v(" "),
          _vm.card.Company_name
            ? _c("p", { staticClass: "registry-item__detail" }, [
                _vm._v(_vm._s(_vm.card.Company_name))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.card.MembershipClass_name
            ? _c("p", { staticClass: "registry-item__detail" }, [
                _vm._v(_vm._s(_vm.card.MembershipClass_name))
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.card.Groups, function(item) {
            return _vm.card.Groups.length
              ? _c("p", { staticClass: "registry-item__detail" }, [
                  item.PersonGroup_name === "Fellows"
                    ? _c("span", [_vm._v("Membership Type: Fellow")])
                    : item.PersonGroup_name === "Supporter"
                    ? _c("span", [_vm._v("Membership Type: Supporter")])
                    : _vm._e()
                ])
              : _vm._e()
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.card.Groups.length
        ? _c(
            "div",
            { staticClass: "registry-item__badges" },
            _vm._l(_vm.card.Groups, function(item) {
              return _c("div", { staticClass: "member-type" }, [
                item.PersonGroup_name === "Fellows"
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "member-type__symbol member-type__symbol--superstar"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "/themes/portal-2021/images/nzpi-fellow-logo.png"
                          }
                        })
                      ]
                    )
                  : item.PersonGroup_name === "CPD 2019"
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "member-type__symbol member-type__symbol--fellow"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/themes/portal-2021/images/CPD_2019.png"
                          }
                        })
                      ]
                    )
                  : item.PersonGroup_name === "CPD 2020"
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "member-type__symbol member-type__symbol--international"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/themes/portal-2021/images/CPD_2020.png"
                          }
                        })
                      ]
                    )
                  : item.PersonGroup_name === "CPD 2021"
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "member-type__symbol member-type__symbol--1"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/themes/portal-2021/images/CPD_2021.png"
                          }
                        })
                      ]
                    )
                  : item.PersonGroup_name === "Supporter"
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "member-type__symbol member-type__symbol--2"
                      },
                      [
                        _c("img", {
                          attrs: {
                            src:
                              "/themes/portal-2021/images/nzpi-supporter-logo1.png"
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ])
            }),
            0
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }