<template>
    <nav class="pagination-container" role="navigation">
        <ul class="pagination">
            <BaseButton :class="{'disabled':isPreviousButtonDisabled}" @click.native="previousPage">
                <a aria-label="Previous"><i class="far fa-long-arrow-alt-left" aria-hidden="true"></i></a>
            </BaseButton>
            <BasePaginationTrigger v-for="paginationTrigger in paginationTriggers" :class="{'active':paginationTrigger === activePage}" :key="paginationTrigger" :pageNumber="paginationTrigger" @loadPage="onLoadPage" />
            <BaseButton :class="{'disabled':isNextButtonDisabled}" @click.native="nextPage">
                <a aria-label="Next"><i class="far fa-long-arrow-alt-right" aria-hidden="true"></i></a>
            </BaseButton>
        </ul>
    </nav>
</template>

<script>
import BaseButton from "./BaseButton.vue";
import BasePaginationTrigger from "./BasePaginationTrigger.vue";

export default {
    components: {
        BaseButton,
        BasePaginationTrigger
    },

    data() {
        return {
            visiblePagesCount: 5
        }
    },

    props: {
        activePage: {
            type: Number,
            required: true,
            default: 0
        },

        pageAmount: {
            type: Number,
            required: true,
            default: 0
        }
    },

    computed: {
        isPreviousButtonDisabled() {
            return this.activePage === 1;
        },

        isNextButtonDisabled() {
            return this.activePage === this.pageAmount;
        },

        paginationTriggers() {
            const activePage = this.activePage;
            const pageAmount = this.pageAmount;
            const visiblePagesCount = this.visiblePagesCount;
            const visiblePagesThreshold = (visiblePagesCount - 1) / 2;
            const pagintationTriggersArray = Array(Math.min(this.visiblePagesCount - 1, pageAmount)).fill(0);

            if (activePage <= visiblePagesThreshold + 1) {
                pagintationTriggersArray[0] = 1;
                const pagintationTriggers = pagintationTriggersArray.map(
                    (paginationTrigger, index) => {
                        return pagintationTriggersArray[0] + index;
                    }
                );

                return pagintationTriggers;
            }

            if (activePage >= (pageAmount - visiblePagesThreshold + 1)) {
                const pagintationTriggers = pagintationTriggersArray.map(
                    (paginationTrigger, index) => {
                        return pageAmount - index;
                    }
                );

                pagintationTriggers.reverse().unshift(1);

                return pagintationTriggers;
            }

            pagintationTriggersArray[0] = activePage - visiblePagesThreshold + 1;
            const pagintationTriggers = pagintationTriggersArray.map(
                (paginationTrigger, index) => {
                    return pagintationTriggersArray[0] + index;
                }
            );

            return pagintationTriggers;
        }
    },

    methods: {
        nextPage() {
            if(this.activePage < this.pageAmount)
            {
                this.$emit("nextPage");
            }
        },

        onLoadPage(value) {
            this.$emit("loadPage", value);
        },

        previousPage() {
            if(this.activePage > 1)
            {
                this.$emit("previousPage");
            }
        }
    }
};
</script>