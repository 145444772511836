<template>
	<div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
		<div class="card card--journal">
			<a v-if="card.Product_urlview" :href="card.Product_urlview">
				<div v-if="card.Images.length" class="img-container card__img">	
					<img :src="card.Images[0].Image_urlview"> 	
				</div>
				<div v-else class="img-container card__img">
					<img src="/images/article-default.png">	
				</div>
				<div class="card__content">
					<div class="card__block">
						<div class="card__subheading" v-if="card.Attachments.length">
							<span class="subheading">{{card.Attachments[0].Attachment_type}}&nbsp;{{card.Attachments[0].Attachment_prettysize}}</span>
						</div>
						<div class="card__title" v-if="card.Product_name">
							<h3 v-html="card.Product_name"></h3>
						</div>
						<p v-if="card.Product_invmemo" v-html="card.Product_invmemo"></p>
					</div>
				</div>
			</a>
		</div>
	</div>
</template>

<script>


import { mapGetters, mapActions, mapState } from 'Node/vuex'


export default {
	name : "ProductCard",

	props:{
		card : {},
	},

	data: function(){
		return{
		}
	},

	methods:{
		getDate: (card)=>{
            let fullDate = card.Product_createdwhen.split(',')[1];
            //let splitDate = fullDate.split(' ');
            //let date = splitDate[0]+' '+splitDate[1]+' '+splitDate[2];
            return fullDate;
        }
		// formatDate(date){
		// 	// format the date 
			
		// 	const d = new Date(date)
		// 	const year = d.getFullYear();
		// 	const months = ["jan", "feb", "mar","apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
		// 	const month = d.getMonth();
		// 	const formatedMonth = months[month]
		// 	const day = d.getDate();

			
		// 	return day + ' ' + formatedMonth + ' ' + year;

		// }

	}, 

	computed: {
		
	},

	mounted() {
		
	},

};

</script>