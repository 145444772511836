<template>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div class="card card--awards">
            <a v-if="card.CompanyProfile_urlview" :href="card.CompanyProfile_urlview">
                <div class="card__content">
                    <div class="card__block">
                        <div class="card__type">
                            <i class="fal fa-trophy"></i>
                        </div>
                        <div class="card__subheading" v-if="card.CompanyProfile_createdwhen">
                            <span class="subheading">{{getAwardYear(card)}} Winner</span>
                        </div>
                        <div class="card__title" v-if="card.CompanyProfile_title">
                            <h3>{{card.CompanyProfile_title}}</h3>
                        </div>
                        <p v-if="card.CompanyProfile_abstract">{{card.CompanyProfile_abstract}}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'Node/vuex'


export default {
    name: "AwardCard",

    props: {
        card: {},
    },

    data: function() {
        return {


        }
    },

    methods: {
        getAwardYear: (card)=>{
            let fullDate = card.CompanyProfile_createdwhen.split(',')[1];
            let year = fullDate.split(' ')[3];
            return year;
        }

    },



    computed: {


    },

    mounted() {


    },

};
</script>