<template>
    <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card card--img card--news">
            <a v-if="card.Story_urlview" :href="card.Story_urlview" style="display: block !important">
                <div class="card__img img-container" v-if="card.Images.length">
                    <img :src="card.Images[0].Image_urlview" >
                </div>
                <div class="card__img img-container" v-else>
                    <img src="/public/images/news-placeholder.png" >
                </div>
                <div class="card__content">
                    <div class="card__block">
                        <div class="card__subheading" v-if="card.Story_whenposted">
                            <span class="subheading">{{card.Story_whenposted.split(',')[1]}}</span>
                        </div>
                        <div class="card__title" v-if="card.Story_name">
                            <h3>{{card.Story_name}}</h3>
                        </div>
                        <p v-if="card.Story_abstract">{{card.Story_abstract}}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'Node/vuex';


export default {
    name: "NewsCard",

    props: {
        card: {},
    },

    data: function() {
        return {


        }
    },

    methods: {


    },



    computed: {


    },

    mounted() {


    },

};
</script>