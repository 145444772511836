////// --- 
//
//
// If you want to improve this filter funtion my suggestion would be to make each filter a promise
//  that's async and set the loading state in between finds.?
//
//
//
//
//
//



export default {

	//- In order to try and make this filter function reusable for all of the cyberglue directories we 
	// will pass the TYPE of content that is being filtered to the Action.
	// This is because cyberglue preffixes all of their objects with the Article Type before what the data is 
	//- eg; Event_name, Event_region, Event_fieldName
	//






	async filterContent({commit, state}, type) {

    // this assumes that the state is tracking selected filters

    let dataType = type;
    

    let keyword = state.selectedFilters.keyword,
    location = state.selectedFilters.location,
    category = state.selectedFilters.category,
    level = state.selectedFilters.level,
    
    filteredContent = state.content;


    // if any of the filterable states are set then filter them

    if (keyword || location || category || level){

    	///////-------
    	if (keyword) { 

        filteredContent = filteredContent.filter((content) => {

          let eventName = content[dataType + '_name'].toLowerCase();
          let eventAbstract;
          if(  content[dataType + '_abstract'] ){
            eventAbstract = content[dataType + '_abstract'].toLowerCase();
          } else {
            eventAbstract = '';
          }
    
          return ( eventName.includes( keyword.toLowerCase() ) ||  eventAbstract.includes( keyword.toLowerCase() ) );

        } )
      }

    	///////-------
    	if (location) {
    		filteredContent = filteredContent.filter((content) => {
    			return content[dataType + '_region'].toLowerCase() === location.toLowerCase();
    		});
    	}
    	
    	///////-------
    	if (category) {
    		filteredContent = filteredContent.filter((content) => {
    			return content[dataType + '_type'].toLowerCase() === category.toLowerCase();
    		});
    	}

        ///////-------
      if (level) {
        filteredContent = filteredContent.filter((content) => {
          return content[dataType + '_level'].toLowerCase() === level.toLowerCase();
        });
      }





      	//- Set the Filtered state to true (YOU'VE JUST FILTERED)	
      	commit('setFiltered', true)
      } else{
      	//- Set the Filtered state to false (There's no filters applied)
      	commit('setFiltered', false)
      }

      //- Set the content based on whatever filters have/n't been applied
      commit('setFilteredContent', filteredContent);
    }

  };