// News Filtering
import store from './store.js'
import Filters from './components/DirectoryFilters.vue';
import Cards from './components/DirectoryContent.vue';


export const DirectoryModules = {

	store : store,

	components : {
		'#DirectoryFilters' : Filters,
		'#DirectoryContent'	: Cards
	},
};
