<template>
    <section v-if="getDefaultHidden" class="section" ref="proto">
        <div class="container card-container">
            <Loader :active="isLoading" :is-full-page="true" color="#5E8D9A">
                <template v-slot:after v-if="loadingText">
                    <div class="loading-text">
                        <h3>{{loadingText}}</h3>
                    </div>
                </template>
            </Loader>
            <div class="row" v-if="getTableId !== '1000005' && getTableId !== '1000011'">
                <div class="col-lg-12 line-heading">
                    <h3>{{ getTotalItems }} Results Found</h3>
                </div>
            </div>
            <!-- <div class="row">
                <Loader v-if="isLoading" ></Loader> -->
            <div class="row" v-if="allContent.length && getTableId === '1000001' && !isError">
                <ArticleCard v-if="allContent.length && getTableId === '1000001'" v-for="(item,index) in allContent" :key="index" :card="item"></ArticleCard>
            </div>
            <div class="row" v-else-if="allContent.length && getTableId === '1000002' && !isError">
                <NewsCard v-for="(item, index) in allContent" :key="index" :card="item"></NewsCard>
            </div>
            <div class="row" v-else-if="allContent.length && getTableId === '1000003' && !isError">
                <ProductCard v-for="(item, index) in allContent" :key="index" :card="item"></ProductCard>
            </div>
            <div class="row" v-else-if="allContent.length && getTableId === '1000013' && !isError">
                <AwardCard v-for="(item, index) in allContent" :key="index" :card="item"></AwardCard>
            </div>
            <div class="row" v-else-if="allContent.length && getTableId === '1000005' && !isError">
                <ConsultantCard v-for="(item, index) in allContent" :key="index" :card="item"></ConsultantCard>
            </div>
            <div class="row" v-else-if="allContent.length && getTableId === '1000006' && !isError">
                <div class="col-xs-12">
                    <JobCard v-for="(item, index) in allContent" :key="index" :card="item"></JobCard>
                </div>
            </div>
            <div class="row" v-else-if="allContent.length && (getTableId === '1000007' || getTableId === '233') && !isError">
                <EventCard v-for="(item, index) in allContent" :key="index" :card="item"></EventCard>
            </div>
            <div class="row" v-else-if="allContent.length && getTableId === '1000011' && !isError">
                <section class="section">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12 text-center seaction-heading">
                                <h2>{{ getTotalItems }} Current Members</h2>
                                <!-- <p class="page-intro">This is page intro text. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><a class="more" href="#">Learn About Becoming a member</a> -->
                            </div>
                        </div>
                    </div>
                </section>
                <section class="section">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="member-type-legend">
                                    <div class="member-type"><span class="member-type__symbol member-type__symbol--superstar"><img src="/themes/portal-2021/images/nzpi-fellow-logo.png"></span><span class="member-type__text">NZPI <br> Fellow</span></div>
                                    <div class="member-type"><span class="member-type__symbol member-type__symbol--fellow"><img src="/themes/portal-2021/images/CPD_2019.png"></span><span class="member-type__text">NZPI <br>  CPD 2019</span></div>
                                    <div class="member-type"><span class="member-type__symbol member-type__symbol--international"><img src="/themes/portal-2021/images/CPD_2020.png"></span><span class="member-type__text">NZPI <br>  CPD 2020</span></div>
                                    <div class="member-type"><span class="member-type__symbol member-type__symbol--1"><img src="/themes/portal-2021/images/CPD_2021.png"></span><span class="member-type__text">NZPI <br> CPD 2021</span></div>
                                    <div class="member-type"><span class="member-type__symbol member-type__symbol--2"><img src="/themes/portal-2021/images/nzpi-supporter-logo1.png"></span><span class="member-type__text">NZPI <br> Supporter</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <PeopleCard v-for="(item, index) in allContent" :key="index" :card="item"></PeopleCard>
            </div>
            <!-- <Error v-if="isError"/>  -->
            <div class="col-xs-12">
                <BasePagination :activePage="currentPage" :pageAmount="pageCount" class="articles-list__pagination" @nextPage="pageChangeHandle('next');" @previousPage="pageChangeHandle('previous');" @loadPage="pageChangeHandle" />
            </div>
            <Error v-if="isError" />
        </div>
        </div>
    </section>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'Node/vuex';
import Loader from 'Node/vue-loading-overlay';
import 'Node/vue-loading-overlay/dist/vue-loading.css';
import Error from 'CustomVueComponents/utils/_error/_error.vue';
import ArticleCard from 'CustomVueComponents/_cards/_articleCard';
import NewsCard from 'CustomVueComponents/_cards/_newsCard';
import ProductCard from 'CustomVueComponents/_cards/_productCard';
import AwardCard from 'CustomVueComponents/_cards/_awardCard';
import ConsultantCard from 'CustomVueComponents/_cards/_consultantCard';
import JobCard from 'CustomVueComponents/_cards/_jobCard';
import EventCard from 'CustomVueComponents/_cards/_eventCard';
import PeopleCard from 'CustomVueComponents/_cards/_peopleCard';
import BasePagination from 'CustomVueComponents/utils/_pagination/BasePagination.vue';
import $ from 'Node/jquery';


export default {
    components: {
        Loader,
        Error,
        PeopleCard,
        ArticleCard,
        NewsCard,
        ProductCard,
        AwardCard,
        ConsultantCard,
        JobCard,
        EventCard,
        BasePagination,
    },

    methods: {
        ...mapActions(['fetchData']),
        ...mapMutations(['setIsLoading', 'setLoadingText', 'INCREMENT_PAGE', 'DECREMENT_PAGE', 'SPECIFY_PAGE']),

        pageChangeHandle: function(value) {

            switch (value) {
                case "next":
                    this.INCREMENT_PAGE();
                    break;
                case "previous":
                    this.DECREMENT_PAGE();
                    break;
                default:
                    this.SPECIFY_PAGE(value);
                    break;
            }
            this.setIsLoading(true);
            this.fetchData({ resetData: true });
            let element = this.$refs.proto;
            let top = element.offsetTop;
            window.scrollTo(0, top);
        },

        news: function() {

            if (this.isFiltered) {

                if (this.contentFiltered.length) {

                    return this.contentFiltered;

                } else {

                    return [];
                }
            }
            this.setIsLoading(false);

            return this.allContent;
        },
    },
    data() {
        return {}
    },
    computed: {
        ...mapGetters([
            'allContent',
            'isFiltered',
            'isLoading',
            'loadingText',
            'isError',
            'getDefaultHidden',
            'getTableId',
            'pageCount',
            'getitemsPerPage',
            'getTotalItems',
            'currentPage'
        ]),
    },

    created() {
        this.fetchData();
    }
};
</script>