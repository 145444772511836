<template>
    <div id="DirectoryFilters" class="card-filters">
        <h1>{{getPageName}}</h1>
        <form id="filter-form">
            <vFormGroup class="directory-filter" v-if="getHeadfilter.name">
                <vInput placeholder="Type here" name="keyword-search" v-model="keywordFilter"></vInput>
                <vSelect :options="getHeadfilter.options" v-model="headerFilter" :placeholder="getHeadfilter.name"></vSelect>
            </vFormGroup>
            <vFormGroup class="directory-filter" style="display:block !important" v-else>
                <vInput placeholder="Type here" name="keyword-search" v-model="keywordFilter" style="width: 100% !important"></vInput>
            </vFormGroup>
            <div class="search-options-container">
                <vButton v-if="getOtherFilters.length" @click="toggleAdditionalFilters" role="button" class="btn btn-link"> {{ additionalFiltersOpen ? "Fewer" : "More" }} Search Options <i class="far fa-chevron-down"></i></vButton>
                <SmoothReflow :options="{ transitionEvent: { selector: '.additional-filters' } }">
                    <div v-show="additionalFiltersOpen" class="additional-filters">
                        <vFormGroup v-for="(filter,index) in getOtherFilters" :key="filter.id" :name="filter.name" :label="filter.name">
                            <vSelect :options="filter.options" ref="filter" placeholder="[Any]"></vSelect>
                        </vFormGroup>
                    </div>
                </SmoothReflow>
                <vButton class="btn btn-default" @click="submit" type="submit">
                    <i class="fas fa-search"></i>
                    Search
                </vButton>
            </div>
        </form>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'Node/vuex';
import vFormGroup from 'CustomVueComponents/_forms/_formGroup';
import vInput from 'CustomVueComponents/_forms/_input';
import vButton from 'CustomVueComponents/_forms/_button';
import vSelect from 'Node/vue-select';
import SmoothReflow from 'CustomVueComponents/utils/SmoothReflow';

export default {
    components: {
        vFormGroup,
        SmoothReflow,
        vInput,
        vButton,
        vSelect,
    },
    data : function() {
        return {
            keywordFilter: '',
            headerFilter: '',
            selectedFilter: [],
            additionalFiltersOpen   : false,
        }
    },
    methods: {
        ...mapActions(['fetchData', 'loading']),
        ...mapMutations(['setIsLoading', 'setKeyword', 'setSelectedFilters', 'resetCurrentPage', 'setFiltered', 'setDefaultHidden']),

        toggleAdditionalFilters: function() {
            event.preventDefault();
            this.additionalFiltersOpen ? this.additionalFiltersOpen = false : this.additionalFiltersOpen = true;
        },

        submit: function() {
            this.setIsLoading(true);
            if (this.selectedFilter.length) {
                this.selectedFilter.length = 0
            };
            //console.log(this.keywordFilter);
            //console.log(this.headerFilter);
            //get header filter value;
            if (this.headerFilter) {
                let ob = {};
                ob.id = this.getHeadfilter.id;
                this.headerFilter.value ? ob.value = this.headerFilter.value : '[Any]';
                this.selectedFilter.push(ob);
            } else {
                let ob = {};
                if (this.getHeadfilter.id) {
                    let ob = {};
                    ob.id = this.getHeadfilter.id
                    ob.value = '[Any]';
                    this.selectedFilter.push(ob);
                }
            }

            //get additional filters value
            if (this.$refs.filter) {
                let otherFilters = this.$refs.filter;
                //console.log(this.$refs.filter);
                for (let i = 0; i < otherFilters.length; i++) {
                    //console.log(otherFilters[i]);
                    //console.log(otherFilters[i].selectedValue);
                    let ob = {};
                    ob.id = this.getOtherFilters[i].id;
                    if (otherFilters[i].selectedValue.length) {
                        ob.value = otherFilters[i].selectedValue[0].value;
                        this.selectedFilter.push(ob);
                    } else {
                        ob.value = '[Any]';
                        this.selectedFilter.push(ob);
                    }
                };
            } else {
                if (this.getOtherFilters.length) {
                    for (let i = 0; i < this.getOtherFilters.length; i++) {
                        let ob = {};
                        ob.id = this.getOtherFilters[i].id;
                        ob.value = '[Any]';
                        this.selectedFilter.push(ob);
                    }
                }
            }
            //console.log(this.selectedFilter);
            this.setKeyword(this.keywordFilter);
            this.setSelectedFilters(this.selectedFilter);
            this.fetchData({ resetCurrentPage: true, resetData: true });
            this.setFiltered(true);
            this.setDefaultHidden(true);
        },
    },

    computed: {
        ...mapGetters(['getHeadfilter', 'getPageName', 'getOtherFilters','getAbstract' ]),
    },
    mounted() {
        this.fetchData({ resetCurrentPage: true, resetData: true })
    },
    created() {
        
    }
};
</script>