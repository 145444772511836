var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.label ? _c("label", [_vm._v(_vm._s(_vm.label))]) : _vm._e(),
      _vm._v(" "),
      _vm.inputDetail
        ? _c("span", { domProps: { innerHTML: _vm._s(_vm.inputDetail) } })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.inputCaption
        ? _c("span", { domProps: { innerHTML: _vm._s(_vm.inputCaption) } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }