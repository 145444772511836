import Vue from 'Node/vue'
import Vuex from 'Node/vuex'
import axios from 'Node/axios';
import filterContent from 'SharedVuex/actions/filterContent';
import InfiniteLoading from 'Node/vue-infinite-loading';
import { debounce } from "Node/lodash";

const queryString = require('Node/query-string');
Vue.use(Vuex);
Vue.use(InfiniteLoading, {
    props: {
        distance: '200'
    },
    system: {
        throttleLimit: 1000
    },
    slots: {
        noMore: ' ',
        error: ' ',
        noResults: ' ',
        spinner: ' '
    }
});
// root state object.
// each Vuex instance is just a single state tree.
const state = {
    DataFilter_tablename: '',
    DataFilter_id: '',
    page_abstract:'',
    selectedFilters: [],
    headerFilter: {
        id: 0,
        name: '',
        options: [],
        section:''
    },
    otherFilters: [],
    dataFilterQuery: {},
    currentPage: 1,
    totalPages: 0,
    itemsPerPage: 0,
    totalItems:0,
    keyword: '',
    isError: false,
    // have you filled all the rows
    // fullPage: false,
    // the total amount of cards displayed
    data: [],
    filtered: false,
    error: false,
    isLoading: true,
    loadingText: '',
    initialLoad: true,
    defaultHidden:true
};

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {

    // fetch the list of possible 'tags'
    async fetchFilters({ commit, state },payload) {
        const response = await axios.get('/DataFilterFind?Action=AjaxDataFilterFindOptions&DataFilterFind_id=' + payload.id);
        //console.log(response.data);
        let resArray = response.data.options;
        //resArray.filter();
        if (Array.isArray(resArray)) {
            if(state.headerFilter.id === payload.id) {
                commit('setHeaderOptions',resArray);
            }else{
                    payload.options = resArray;
                    commit('addOtherFilters',payload);  
                }
        };
            //console.log(response.data.options);
    },

    fetchData: debounce(function({ commit, dispatch, state }, payload) {
        commit('setIsLoading', true);
        commit('setLoadingText', "Loading...");
        if (payload != null) {
            if (payload.resetCurrentPage != null && payload.resetCurrentPage) {
                commit('resetCurrentPage');
            }
            if (payload.resetData != null && payload.resetData) {
                commit('resetData');
            }
        }

        state.dataFilterQuery = {};

        let dataFilterQuery = state.dataFilterQuery,
            keyword = state.keyword,
            selectedFilters = state.selectedFilters;

        if (Object.keys(dataFilterQuery).length === 0) {
            let parsedQuery = queryString.parse(location.search);
            let id = document.getElementById("datafilter_id").value;
            parsedQuery["DataFilter_id"] = document.getElementById("datafilter_id").value;
            //console.log(parsedQuery);
            parsedQuery["Action"] = "AjaxList";
            commit('setDataFilter_id',id);
            commit('setDataFilterQuery', parsedQuery);
            dataFilterQuery = parsedQuery;
            console.log(dataFilterQuery);
        }

        console.log(selectedFilters,keyword,dataFilterQuery);
        if (selectedFilters.length || keyword) {
            if (selectedFilters.length) {
                for (let i=0; i< selectedFilters.length; i++) {
                    dataFilterQuery["DFF_" + selectedFilters[i].id] = selectedFilters[i].value;
                }
            }
            if (keyword) {
                dataFilterQuery["DataFilter_Find"] = keyword;
            }
            commit('setDataFilterQuery', dataFilterQuery);
        }
        if(state.currentPage===0){
            state.currentPage++;
        }
        dataFilterQuery["Page"] = state.currentPage-1;
        console.log(dataFilterQuery);
        // check the environment and log dev mode to console
        if (webpackHotUpdate) {
            console.log('In Dev Mode');
        }
        axios.get(queryString.stringifyUrl({ url: '/DataFilter', query: state.dataFilterQuery }))
            .then(function(response) {
                if (response.status === 200) {
                    if (response.data) {
                        console.log(response);
                        if (response.data.finds) {
                            let finds = response.data.finds;
                            console.log(finds);
                            if (state.headerFilter.id === 0 && state.otherFilters.length === 0) {
                                for (let i = 0; i < finds.length; i++) {
                                    let payload = {};
                                        payload.id = finds[i].DataFilterFind_id;
                                        payload.name = finds[i].DataFilterFind_name;
                                        payload.section = finds[i].DataFilterFind_section;
                                        payload.options = [];
                                    if (finds[i].DataFilterFind_section == "Header") {
                                        commit('setHeaderFilter', payload);
                                        dispatch('fetchFilters',payload);
                                    }else{
                                        dispatch('fetchFilters',payload);
                                    }
                                }
                            }
                        }
                        commit('setDataFilterTablename', response.data.DataFilter_name);
                        commit('setAbstract', response.data.DataFilter_text);
                        commit('setTotalPages', response.data.TotalPages);
                        commit('setItemsPerPage', response.data.ItemsPerPage);
                        commit('setTotalItems', response.data.TotalItems);
                        commit('addData', response.data.Items);
                        console.log(state);
                        setTimeout(function() { commit('setIsLoading', false); }, 300);
                    }
                } else {
                    commit('setError', true);
                }
            })
    }, 400),

};


// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.

const mutations = {
    setDataFilterQuery: (state, dataFilterQuery) => { state.dataFilterQuery = dataFilterQuery },
    setDataFilter_id: (state, id) =>{
        state.DataFilter_id = id
    },
    setAbstract:(state,abstract)=>{
        state.page_abstract = abstract
    },
    resetCurrentPage: (state) => { state.currentPage = 0 },
    setTotalPages: (state, totalPages) => { state.totalPages = totalPages },
    setItemsPerPage: (state, itemsPerPage) => { state.itemsPerPage = itemsPerPage },
    setTotalItems: (state, totalItems) => { state.totalItems = totalItems},
    resetData: (state) => { state.data = [] },
    addData: (state, data) => { state.data = state.data.concat(data) },
    setHeaderOptions: (state, options) => {
        state.headerFilter.options = options;
    },
    setHeaderFilter: (state, head) => {
        state.headerFilter = head;
    },
    addOtherFilters: (state, otherfilter) => {
        state.otherFilters = state.otherFilters.concat(otherfilter);
    },
    setKeyword:(state,keyword) =>{
        state.keyword = keyword
    },
    setSelectedFilters:(state,selectedFilters)=>{
        state.selectedFilters = selectedFilters
    },
    setDataFilterTablename: (state, dataFilterTablename) => { state.DataFilter_tablename = dataFilterTablename },
    setFiltered: (state, bool) => (state.filtered = bool),
    setError: (state, bool) => (state.error = bool),
    setDefaultHidden: (state, bool) => (state.defaultHidden = bool),
    INCREMENT_PAGE:(state)=>(state.currentPage++),
    DECREMENT_PAGE:(state)=>(state.currentPage--),
    SPECIFY_PAGE(state, page) {state.currentPage = page;},

    setIsLoading: (state, bool) => (state.isLoading = bool),
    setLoadingText: (state, loadingText) => {
        state.loadingText = loadingText;
    },
    initialLoadDone: (state) => (state.initialLoad = false),
};

// getters are functions

const getters = {
    getDefaultHidden(state) { return state.defaultHidden },
    getHeadfilter(state) { return state.headerFilter },
    // fullPage: state => state.totalCards % 4 === 0 ? true : false,
    getAbstract(state){
        return state.page_abstract
    },
    getPageName(state) { return state.DataFilter_tablename },
    getOtherFilters(state) { return state.otherFilters },
    allContent(state) { return state.data },
    contentFiltered(state) { return state.filteredContent },
    isError(state) { return state.isError },
    isFiltered(state) { return state.filtered },
    getKeyword(state) { return state.keyword },
    getTableId(state) { return state.DataFilter_id},
    getTotalItems(state) { return state.totalItems},
    pageCount(state) { return state.totalPages },
    getitemsPerPage(state) { return state.itemsPerPage},
    currentPage: state => { return state.currentPage},
    loadingText(state) { return state.loadingText },
    isLoading(state) { return state.isLoading },
};

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
});