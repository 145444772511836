<template>
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div class="card card--resources">
            <a v-if="card.Article_urlview" :href="card.Article_urlview">
                <div class="card__content">
                    <div class="card__block">
                        <div class="card__type"> <i class="fal fa-file-alt"> </i></div>
                        <div v-if="card.Article_whenposted" class="card__subheading"><span class="subheading">{{card.Article_whenposted.split(',')[1]}}</span></div>
                        <div v-if="card.Article_title" class="card__title">
                            <h3 v-html="card.Article_title"></h3>
                        </div>
                    </div>
                    <p v-if="card.Article_abstract" v-html="card.Article_abstract"></p>
                    <div class="card__info">
                        <ul>
                            <li class="list__item cpd__item" v-if="card.Event_idcpdactivitytype === '1000000'">
                                <span class="list__icon cpd-shape category-1">{{card.CPDCompetencies[0].ArticleCPDCompetencyRel_points*1}}</span>
                                <div class="list__content">
                                    <span>CPD Points
                                        <span>{{card.CPDCompetencies[0].ArticleCPDCompetencyRel_name}}</span>
                                    </span>
                                </div>
                            </li>
                            <li class="list__item cpd__item" v-if="card.Event_idcpdactivitytype === '1000001'">
                                <span class="list__icon cpd-shape category-2">{{card.CPDCompetencies[0].ArticleCPDCompetencyRel_points*1}}</span>
                                <div class="list__content">
                                    <span>CPD Points
                                        <span>{{card.CPDCompetencies[0].ArticleCPDCompetencyRel_name}}</span>
                                    </span>
                                </div>
                            </li>
                            <li class="list__item cpd__item" v-if="card.Event_idcpdactivitytype === '1000002'">
                                <span class="list__icon cpd-shape category-3">{{card.CPDCompetencies[0].ArticleCPDCompetencyRel_points*1}}</span>
                                <div class="list__content">
                                    <span>CPD Points
                                        <span>{{card.CPDCompetencies[0].ArticleCPDCompetencyRel_name}}</span>
                                    </span>
                                </div>
                            </li>

                            <li class="list__item" v-if="card.Products.length" v-for="item in card.Products">
                                <span class="list__icon">
                                    <i class="fal fa-shopping-cart"></i>
                                </span>
                                <div class="list__content">
                                    <span>{{item.Prices[1].Product_pricetaxincl}}
                                        <span>Members</span>
                                    </span>
                                    <span>{{item.Prices[0].Product_pricetaxincl}}
                                        <span>Regular</span>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'Node/vuex';


export default {
    name: "ArticleCard",

    props: {
        card: {},
    },

    data: function() {
        return {


        }
    },

    methods: {


    },



    computed: {


    },

    mounted() {


    },

};
</script>