import Vue from 'Node/vue';
import Popover from 'Node/vue-js-popover'
import VueLoader from './loaders/VueLoader';

import './utils/utils.js'


import ComponentLoader from './loaders/ComponentLoader';

import { components } from './components';

// import { offCanvasNavigation } from './components/navigation/_OffCanvasNav.js' 
// import { datePicker } from './components/navigation/_datepicker.js' 

import { EventModules } from './vue/modules/EventDirectory/EventDirectory.js';
import { CpdHistoryModules } from './vue/modules/CpdHistory/CpdHistory.js';
import { JobModules } from './vue/modules/JobDirectory/JobDirectory.js';
import { NewsModules } from './vue/modules/NewsDirectory/NewsDirectory.js';
import { DirectoryModules } from './vue/modules/Directory/Directory.js';

Vue.use(Popover)
Vue.config.productionTip = true;

const CLoader = new ComponentLoader(components);

const EventLoader = new VueLoader(EventModules);
const NewsLoader = new VueLoader(NewsModules);
const CpdLoader = new VueLoader( CpdHistoryModules );
const JobLoader = new VueLoader( JobModules );
const DirectoryLoader = new VueLoader( DirectoryModules);



document.addEventListener("DOMContentLoaded", function () {
	
	if (components) {
		CLoader.load();
	}

	// list loaders as needed ....

	// //  https://vuejs.org/v2/guide/components-dynamic-async.html#Handling-Loading-State
	NewsLoader.load();
	EventLoader.load();
	JobLoader.load();
	CpdLoader.load();
	DirectoryLoader.load();


});




//scroll to top of page


if( document.getElementById('scroll-page-top')){


	window.addEventListener('scroll', function(e) { 

		let backToTopButton = document.getElementById('scroll-page-top')
		let windowHeight = window.innerHeight
		let windowPos = window.pageYOffset | document.body.scrollTop

		if( windowPos > windowHeight ){
			document.getElementById('scroll-page-top').classList.add('show')
		}
		if( windowPos < windowHeight ){
			document.getElementById('scroll-page-top').classList.remove('show')
		}



	});
};


if(document.getElementById('scroll-page-top')){
	document.getElementById('scroll-page-top').addEventListener('click', ()=>{   
		window.scrollTo({
			top: 0, 
			left: 0, 
			behavior: 'smooth'
		}) 
	});
}


//// JQUERY ///
/////
//
////
//// Spaghetti Code :(


const $ = require('Node/jquery');

// Bootstrap 3 Tooltips
$(document).ready(function(){

	$('[data-toggle="popover"]').popover();


   // mega nav
   $('.mega-nav .dropdown-menu .dropdown-toggle').on('click', function (e) {

   	e.stopPropagation();
   	$(this).parent().toggleClass('open');
   	$(this).parent().parent().parent().siblings('.dropdown').removeClass('open');


   });
   $('.mega-nav .close').on('click', function (e) {

   	$('#mega-nav__menu').removeClass('open');

   });

$(document).ready(function(){
  // $("button:not('#mega-nav-trigger')").click(function(){
  //   $("p").toggle();
  // });
});



/// - If you are on the dashboard page!
//
//	Check the window size! If uou are on larger screens, remive the data-target from the buttons 
//	on the tiles so that they will not trigger a collapse and show them if they are not shown
//
//	otherwise add the data-target for the nearest __content so that the triggers will work
//
//


   if( $('.dashboard-tile') ){

   	const dashboardTileCheck = function(){

   		if($(window).width() > 768 ){

   			$('.dashboard-tile__button').each( (i, obj) =>{
   				$(obj).attr('data-target', '');

   			})	
   			$('.dashboard-tile__content').collapse('show');
   			
   		} else {

   					$('.dashboard-tile__button').each( (i, obj) =>{
   				$(obj).attr('data-target',  '#' + $(obj).siblings('.dashboard-tile__content').attr('id'));

   			})	
   		}
   	};

   	dashboardTileCheck();
   	
   	$(window).on('resize', dashboardTileCheck );
   }




 });  


