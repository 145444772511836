<template>
    <div class="col-xs-12 col-sm-6 col-md-4">
        <div class="card card--img card--event">
            <a v-if="card.Event_urlview" :href="card.Event_urlview" style="display: block !important">
                <div class="card__img img-container" v-if="card.Images.length">
                    <img :src="card.Images[0].Image_urlview" >
                </div>
                <div class="card__img img-container" v-else>
                    <img src="/public/images/news-image-1.jpg" >
                </div>
                <div class="card__content">
                    <div class="card__block">
                        <div class="card__subheading">
                            <span class="subheading" v-if="card.Event_xtr_host && card.Event_type">{{card.Event_xtr_host}} | {{card.Event_type}}</span>
                            <span class="subheading" v-else-if="!card.Event_xtr_host && card.Event_type" v-html="card.Event_type"></span>
                            <span class="subheading" v-else-if="card.Event_xtr_host && !card.Event_type" v-html="card.Event_xtr_host"></span>
                            <span class="subheading" v-else v-html="card.Event_type"></span>
                        </div>
                        <div class="card__title">
                            <h3 v-html="card.Event_name"></h3>
                        </div>
                        <p v-if="card.Event_abstract">{{card.Event_abstract}}</p>
                    </div>
                </div>
                <div class="card__info">
                    <ul>
                        <li class="list__item" v-if="card.Event_datestring">
                            <span class="list__icon">
                                <i class="fal fa-calendar-alt"></i>
                            </span>
                            <div class="list__content">
                                <span v-html="formatDate(card.Event_datestring)"></span>
                            </div>
                        </li>
                        <li class="list__item" v-if="card.Event_city"><span class="list__icon"><i class="fal fa-map-marker-alt"></i></span>
                            <div class="list__content"><span>{{card.Event_city}}</span></div>
                        </li>

                        <li class="list__item cpd__item" v-if="card.Event_idcpdactivitytype === '1000000'">
                            <span class="list__icon cpd-shape category-1">{{card.CPDCompetencies[0].EventCPDCompetencyRel_points*1}}</span>
                            <div class="list__content">
                                <span>CPD Points
                                    <span>CATEGORY 1</span>
                                </span>
                            </div>
                        </li>
                        <li class="list__item cpd__item" v-if="card.Event_idcpdactivitytype === '1000001'">
                            <span class="list__icon cpd-shape category-2">{{card.CPDCompetencies[0].EventCPDCompetencyRel_points*1}}</span>
                            <div class="list__content">
                                <span>CPD Points
                                    <span>CATEGORY 2</span>
                                </span>
                            </div>
                        </li>
                        <li class="list__item cpd__item" v-if="card.Event_idcpdactivitytype === '1000002'">
                            <span class="list__icon cpd-shape category-3">{{card.CPDCompetencies[0].EventCPDCompetencyRel_points*1}}</span>
                            <div class="list__content">
                                <span>CPD Points
                                    <span>CATEGORY 3</span>
                                </span>
                            </div>
                        </li>
                        
                        <li class="list__item" v-if="card.Products.length" v-for="item in card.Products">
                            <span class="list__icon">
                                <i class="fal fa-ticket"></i>
                            </span>
                            <div class="list__content">
                                <span>{{item.Prices[1].Product_pricetaxincl}}
                                    <span>Members</span>
                                </span>
                            </div>
                        </li>
                        <li class="list__item" v-if="card.Products.length" v-for="item in card.Products">
                            <span class="list__icon">
                                <i class="fal fa-ticket"></i>
                            </span>
                            <div class="list__content">
                                <span>{{item.Prices[0].Product_pricetaxincl}}
                                    <span>Non-Members</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
export default {

    name: 'EventCard',

    props: {

        card: {},

    },
    methods: {
        formatDate(date) {
            // format the date 
            if(date.includes('-')){
                return date.split('-')[0];
            }else{
                return date;
            }
            // let array = date.split('/');
            // const year = array[2];
            // const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            // const month = array[1].replace(/^0+/, '');
            // const formatedMonth = months[month];
            // const day = array[0];
            // return day + ' ' + formatedMonth + ' ' + year;
        }
        // getCPDPoints: (card) => {
        //     //console.log(card);

        //     let cpd = card.CPDCompetencies;
        //     let sum = 0;
        //     let category = [];
        //     for (let i = 0; i < cpd.length; i++) {
        //         if (cpd[i].EventCPDCompetencyRel_points*1) {
        //             sum += cpd[i].EventCPDCompetencyRel_points * 1;
        //             category.push(cpd[i].EventCPDCompetencyRel_name);
        //         }
        //     }
        //     console.log(sum);
        //     return { sum: sum};

        // },
    },
    data() {
        return {

        }
    },
    computed: {
        isCompleted: function() {
            return this.card.Resource_status.toLowerCase() == 'completed';
        }
    },

};
</script>