var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getDefaultHidden
    ? _c("section", { ref: "proto", staticClass: "section" }, [
        _c(
          "div",
          { staticClass: "container card-container" },
          [
            _c("Loader", {
              attrs: {
                active: _vm.isLoading,
                "is-full-page": true,
                color: "#5E8D9A"
              },
              scopedSlots: _vm._u(
                [
                  _vm.loadingText
                    ? {
                        key: "after",
                        fn: function() {
                          return [
                            _c("div", { staticClass: "loading-text" }, [
                              _c("h3", [_vm._v(_vm._s(_vm.loadingText))])
                            ])
                          ]
                        },
                        proxy: true
                      }
                    : null
                ],
                null,
                true
              )
            }),
            _vm._v(" "),
            _vm.getTableId !== "1000005" && _vm.getTableId !== "1000011"
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-12 line-heading" }, [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.getTotalItems) + " Results Found")
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.allContent.length &&
            _vm.getTableId === "1000001" &&
            !_vm.isError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.allContent, function(item, index) {
                    return _vm.allContent.length && _vm.getTableId === "1000001"
                      ? _c("ArticleCard", { key: index, attrs: { card: item } })
                      : _vm._e()
                  }),
                  1
                )
              : _vm.allContent.length &&
                _vm.getTableId === "1000002" &&
                !_vm.isError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.allContent, function(item, index) {
                    return _c("NewsCard", { key: index, attrs: { card: item } })
                  }),
                  1
                )
              : _vm.allContent.length &&
                _vm.getTableId === "1000003" &&
                !_vm.isError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.allContent, function(item, index) {
                    return _c("ProductCard", {
                      key: index,
                      attrs: { card: item }
                    })
                  }),
                  1
                )
              : _vm.allContent.length &&
                _vm.getTableId === "1000013" &&
                !_vm.isError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.allContent, function(item, index) {
                    return _c("AwardCard", {
                      key: index,
                      attrs: { card: item }
                    })
                  }),
                  1
                )
              : _vm.allContent.length &&
                _vm.getTableId === "1000005" &&
                !_vm.isError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.allContent, function(item, index) {
                    return _c("ConsultantCard", {
                      key: index,
                      attrs: { card: item }
                    })
                  }),
                  1
                )
              : _vm.allContent.length &&
                _vm.getTableId === "1000006" &&
                !_vm.isError
              ? _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12" },
                    _vm._l(_vm.allContent, function(item, index) {
                      return _c("JobCard", {
                        key: index,
                        attrs: { card: item }
                      })
                    }),
                    1
                  )
                ])
              : _vm.allContent.length &&
                (_vm.getTableId === "1000007" || _vm.getTableId === "233") &&
                !_vm.isError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.allContent, function(item, index) {
                    return _c("EventCard", {
                      key: index,
                      attrs: { card: item }
                    })
                  }),
                  1
                )
              : _vm.allContent.length &&
                _vm.getTableId === "1000011" &&
                !_vm.isError
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("section", { staticClass: "section" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 text-center seaction-heading"
                            },
                            [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(_vm.getTotalItems) + " Current Members"
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _vm._l(_vm.allContent, function(item, index) {
                      return _c("PeopleCard", {
                        key: index,
                        attrs: { card: item }
                      })
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xs-12" },
              [
                _c("BasePagination", {
                  staticClass: "articles-list__pagination",
                  attrs: {
                    activePage: _vm.currentPage,
                    pageAmount: _vm.pageCount
                  },
                  on: {
                    nextPage: function($event) {
                      return _vm.pageChangeHandle("next")
                    },
                    previousPage: function($event) {
                      return _vm.pageChangeHandle("previous")
                    },
                    loadPage: _vm.pageChangeHandle
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.isError ? _c("Error") : _vm._e()
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "section" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c("div", { staticClass: "member-type-legend" }, [
              _c("div", { staticClass: "member-type" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "member-type__symbol member-type__symbol--superstar"
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/themes/portal-2021/images/nzpi-fellow-logo.png"
                      }
                    })
                  ]
                ),
                _c("span", { staticClass: "member-type__text" }, [
                  _vm._v("NZPI "),
                  _c("br"),
                  _vm._v(" Fellow")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "member-type" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "member-type__symbol member-type__symbol--fellow"
                  },
                  [
                    _c("img", {
                      attrs: { src: "/themes/portal-2021/images/CPD_2019.png" }
                    })
                  ]
                ),
                _c("span", { staticClass: "member-type__text" }, [
                  _vm._v("NZPI "),
                  _c("br"),
                  _vm._v("  CPD 2019")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "member-type" }, [
                _c(
                  "span",
                  {
                    staticClass:
                      "member-type__symbol member-type__symbol--international"
                  },
                  [
                    _c("img", {
                      attrs: { src: "/themes/portal-2021/images/CPD_2020.png" }
                    })
                  ]
                ),
                _c("span", { staticClass: "member-type__text" }, [
                  _vm._v("NZPI "),
                  _c("br"),
                  _vm._v("  CPD 2020")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "member-type" }, [
                _c(
                  "span",
                  { staticClass: "member-type__symbol member-type__symbol--1" },
                  [
                    _c("img", {
                      attrs: { src: "/themes/portal-2021/images/CPD_2021.png" }
                    })
                  ]
                ),
                _c("span", { staticClass: "member-type__text" }, [
                  _vm._v("NZPI "),
                  _c("br"),
                  _vm._v(" CPD 2021")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "member-type" }, [
                _c(
                  "span",
                  { staticClass: "member-type__symbol member-type__symbol--2" },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "/themes/portal-2021/images/nzpi-supporter-logo1.png"
                      }
                    })
                  ]
                ),
                _c("span", { staticClass: "member-type__text" }, [
                  _vm._v("NZPI "),
                  _c("br"),
                  _vm._v(" Supporter")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }