// Job Filtering

import store from './store.js'
import Filters from './components/JobFilters.vue';
import Cards from './components/JobCards.vue';

export const JobModules = {

	store : store,

	components : {

		//
		'#JobFilters' :Filters,
		'#JobCards'	: Cards,
		
	},


};
