<template>


	<div class="vue-input">
		<label v-if="label" :for="id" >{{ label }}</label>
		<div  class="input-group">
			<input :id="id" :placeholder="placeholder" :type="[type ? type : 'text']" :name="name" :value="value"
			 :class="[inputClass ? inputClass : 'form-control']" :aria-requried="required"  :required="required" 	@input="$emit('input', $event.target.value)" >
			<slot></slot>
		</div>
		<slot name="help-text"></slot>

</div>

</template>


<script>

// this component must be wrapped in a FormGorup Component


export default {
	name: "vInput",
	props:{

		// things that don't require special properties
		label:String,
		inputClass: 	String,
		parentClass: 	String,
		placeholder: 	String,	
		id: String,

		type: {
			type: 		String,
			default: 	'text'
		},
		name:{
			type: String,
			required:true,
		}, 			
		error: {
			type: Boolean,
			default: false,
		} ,
		required: {
			type: Boolean,
			default: false
		} ,
		value:{
			
		}
	
	},

	data(){
		return{
			retunedValue: '',
		}
	},



};

</script>