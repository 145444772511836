var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
    _c("div", { staticClass: "card card--img card--news" }, [
      _vm.card.Story_urlview
        ? _c(
            "a",
            {
              staticStyle: { display: "block !important" },
              attrs: { href: _vm.card.Story_urlview }
            },
            [
              _vm.card.Images.length
                ? _c("div", { staticClass: "card__img img-container" }, [
                    _c("img", {
                      attrs: { src: _vm.card.Images[0].Image_urlview }
                    })
                  ])
                : _c("div", { staticClass: "card__img img-container" }, [
                    _c("img", {
                      attrs: { src: "/public/images/news-placeholder.png" }
                    })
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "card__content" }, [
                _c("div", { staticClass: "card__block" }, [
                  _vm.card.Story_whenposted
                    ? _c("div", { staticClass: "card__subheading" }, [
                        _c("span", { staticClass: "subheading" }, [
                          _vm._v(
                            _vm._s(_vm.card.Story_whenposted.split(",")[1])
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.card.Story_name
                    ? _c("div", { staticClass: "card__title" }, [
                        _c("h3", [_vm._v(_vm._s(_vm.card.Story_name))])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.card.Story_abstract
                    ? _c("p", [_vm._v(_vm._s(_vm.card.Story_abstract))])
                    : _vm._e()
                ])
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }