
import scrollPast from './components/_scrollPast';
import countDown from './components/_countDownTimer';
import passwordReveal from './components/forms/_passwordReveal';

export const components = {

	'.scrollPast'							: scrollPast,
	'.countDown'							: countDown,
	'.password-reveal'				: passwordReveal

};
