var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "job-listing" }, [
    _c("div", { staticClass: "content" }, [
      _c("a", { attrs: { href: _vm.card.JobListing_urlview } }, [
        _vm.card.JobListing_whenposted
          ? _c("span", { staticClass: "date-listed" }, [
              _vm._v(
                "\n    \t\t\t" +
                  _vm._s(_vm.card.JobListing_whenposted.split(",")[1]) +
                  "\n    \t\t"
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("h3", [_vm._v(_vm._s(_vm.card.JobListing_title))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.card.JobListing_advertisername))]),
        _vm._v(" "),
        _c("div", { staticClass: "extra-info" }, [
          _vm.card.JobListing_location
            ? _c("span", { staticClass: "extra-info__item" }, [
                _c("i", { staticClass: "fal fa-map-marker-alt" }),
                _vm._v(" "),
                _c("div", { staticClass: "extra-info__details" }, [
                  _c("div", { staticClass: "extra-info__detail" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.card.JobListing_location) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "extra-info__type" }, [
                    _vm._v(
                      "\n                            Region\n                        "
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.card.JobListing_category
            ? _c("span", { staticClass: "extra-info__item" }, [
                _c("i", { staticClass: "fal fa-users" }),
                _vm._v(" "),
                _c("div", { staticClass: "extra-info__details" }, [
                  _c("div", { staticClass: "extra-info__detail" }, [
                    _vm._v(
                      "\n                           " +
                        _vm._s(_vm.card.JobListing_category) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "extra-info__type" }, [
                    _vm._v(
                      "\n                            Level\n                        "
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.card.JobListing_type
            ? _c("span", { staticClass: "extra-info__item" }, [
                _c("i", { staticClass: "fal fa-clock" }),
                _vm._v(" "),
                _c("div", { staticClass: "extra-info__details" }, [
                  _c("div", { staticClass: "extra-info__detail" }, [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.card.JobListing_type) +
                        "\n                        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "extra-info__type" }, [
                    _vm._v(
                      "\n                            Type\n                        "
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "job-logo" }, [
      _vm.card.Images.length
        ? _c("div", { staticClass: "card__img img-container" }, [
            _c("img", {
              staticStyle: { "object-fit": "contain" },
              attrs: { src: _vm.card.Images[0].Image_urlview }
            })
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }