var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12 col-md-4" }, [
    _c("div", { staticClass: "card card--consultant" }, [
      _vm.card.Images.length
        ? _c("div", { staticClass: "card__img" }, [
            _c("img", { attrs: { src: _vm.card.Images[0].Image_urlview } }),
            _vm._v(" "),
            _vm.card.CompanyGroup_name === "NZPI Supporters"
              ? _c("div", { staticClass: "nzpi-supporter" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1)
                ])
              : _vm._e()
          ])
        : _c("div", { staticClass: "card__img" }, [
            _c("i", {
              staticClass: "fal fa-building",
              staticStyle: { "font-size": "50px" }
            }),
            _vm._v(" "),
            _vm.card.CompanyGroup_name === "NZPI Supporters"
              ? _c("div", { staticClass: "nzpi-supporter" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3)
                ])
              : _vm._e()
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "card__content" }, [
        _c("div", { staticClass: "card__block" }, [
          _c("h4", { staticClass: "card__title" }, [
            _vm._v(_vm._s(_vm.card.CompanyProfile_title))
          ])
        ]),
        _vm._v(" "),
        _c("ul", { staticClass: "card__info" }, [
          _vm.card.Locations.length
            ? _c("li", { staticClass: "card__info-item" }, [
                _c("i", { staticClass: "fal fa-map-marker-alt" }),
                _vm._v(" "),
                _c("span", { staticClass: "card__info-text" }, [
                  _vm._v("x" + _vm._s(_vm.card.Locations.length) + " Locations")
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.card.Company_mail
            ? _c("li", { staticClass: "card__info-item" }, [
                _c("i", { staticClass: "fal fa-envelope" }),
                _vm._v(" "),
                _c("span", { staticClass: "card__info-text" }, [
                  _c(
                    "a",
                    { attrs: { href: "mailto:" + _vm.card.Company_mail } },
                    [_vm._v(_vm._s(_vm.card.Company_mail))]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.card.Company_website
            ? _c("li", { staticClass: "card__info-item" }, [
                _c("i", { staticClass: "fal fa-link" }),
                _vm._v(" "),
                _c("span", { staticClass: "card__info-text" }, [
                  _c(
                    "a",
                    { attrs: { href: "//" + _vm.card.Company_website } },
                    [_vm._v(_vm._s(_vm.card.Company_website))]
                  )
                ])
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.card.CompanyProfile_urlview
          ? _c(
              "a",
              {
                staticClass: "more",
                attrs: { href: _vm.card.CompanyProfile_urlview }
              },
              [_vm._v("View profile")]
            )
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nzpi-supporter__logo" }, [
      _c("img", { attrs: { src: "/images/nzpi-supporter-logo.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "nzpi-supporter__text" }, [
      _vm._v("NZPI "),
      _c("br"),
      _vm._v(" Supporter")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nzpi-supporter__logo" }, [
      _c("img", { attrs: { src: "/images/nzpi-supporter-logo.png" } })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "nzpi-supporter__text" }, [
      _vm._v("NZPI "),
      _c("br"),
      _vm._v(" Supporter")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }