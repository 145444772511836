var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4" }, [
    _c("div", { staticClass: "card card--img card--event" }, [
      _vm.card.Event_urlview
        ? _c(
            "a",
            {
              staticStyle: { display: "block !important" },
              attrs: { href: _vm.card.Event_urlview }
            },
            [
              _vm.card.Images.length
                ? _c("div", { staticClass: "card__img img-container" }, [
                    _c("img", {
                      attrs: { src: _vm.card.Images[0].Image_urlview }
                    })
                  ])
                : _c("div", { staticClass: "card__img img-container" }, [
                    _c("img", {
                      attrs: { src: "/public/images/news-image-1.jpg" }
                    })
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "card__content" }, [
                _c("div", { staticClass: "card__block" }, [
                  _c("div", { staticClass: "card__subheading" }, [
                    _vm.card.Event_xtr_host && _vm.card.Event_type
                      ? _c("span", { staticClass: "subheading" }, [
                          _vm._v(
                            _vm._s(_vm.card.Event_xtr_host) +
                              " | " +
                              _vm._s(_vm.card.Event_type)
                          )
                        ])
                      : !_vm.card.Event_xtr_host && _vm.card.Event_type
                      ? _c("span", {
                          staticClass: "subheading",
                          domProps: { innerHTML: _vm._s(_vm.card.Event_type) }
                        })
                      : _vm.card.Event_xtr_host && !_vm.card.Event_type
                      ? _c("span", {
                          staticClass: "subheading",
                          domProps: {
                            innerHTML: _vm._s(_vm.card.Event_xtr_host)
                          }
                        })
                      : _c("span", {
                          staticClass: "subheading",
                          domProps: { innerHTML: _vm._s(_vm.card.Event_type) }
                        })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "card__title" }, [
                    _c("h3", {
                      domProps: { innerHTML: _vm._s(_vm.card.Event_name) }
                    })
                  ]),
                  _vm._v(" "),
                  _vm.card.Event_abstract
                    ? _c("p", [_vm._v(_vm._s(_vm.card.Event_abstract))])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card__info" }, [
                _c(
                  "ul",
                  [
                    _vm.card.Event_datestring
                      ? _c("li", { staticClass: "list__item" }, [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("div", { staticClass: "list__content" }, [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.formatDate(_vm.card.Event_datestring)
                                )
                              }
                            })
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.Event_city
                      ? _c("li", { staticClass: "list__item" }, [
                          _vm._m(1),
                          _vm._v(" "),
                          _c("div", { staticClass: "list__content" }, [
                            _c("span", [_vm._v(_vm._s(_vm.card.Event_city))])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.Event_idcpdactivitytype === "1000000"
                      ? _c("li", { staticClass: "list__item cpd__item" }, [
                          _c(
                            "span",
                            { staticClass: "list__icon cpd-shape category-1" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.card.CPDCompetencies[0]
                                    .EventCPDCompetencyRel_points * 1
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(2)
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.Event_idcpdactivitytype === "1000001"
                      ? _c("li", { staticClass: "list__item cpd__item" }, [
                          _c(
                            "span",
                            { staticClass: "list__icon cpd-shape category-2" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.card.CPDCompetencies[0]
                                    .EventCPDCompetencyRel_points * 1
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(3)
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.Event_idcpdactivitytype === "1000002"
                      ? _c("li", { staticClass: "list__item cpd__item" }, [
                          _c(
                            "span",
                            { staticClass: "list__icon cpd-shape category-3" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.card.CPDCompetencies[0]
                                    .EventCPDCompetencyRel_points * 1
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(4)
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.card.Products, function(item) {
                      return _vm.card.Products.length
                        ? _c("li", { staticClass: "list__item" }, [
                            _vm._m(5, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "list__content" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.Prices[1].Product_pricetaxincl) +
                                    "\n                                "
                                ),
                                _c("span", [_vm._v("Members")])
                              ])
                            ])
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.card.Products, function(item) {
                      return _vm.card.Products.length
                        ? _c("li", { staticClass: "list__item" }, [
                            _vm._m(6, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "list__content" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.Prices[0].Product_pricetaxincl) +
                                    "\n                                "
                                ),
                                _c("span", [_vm._v("Non-Members")])
                              ])
                            ])
                          ])
                        : _vm._e()
                    })
                  ],
                  2
                )
              ])
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "list__icon" }, [
      _c("i", { staticClass: "fal fa-calendar-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "list__icon" }, [
      _c("i", { staticClass: "fal fa-map-marker-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list__content" }, [
      _c("span", [
        _vm._v("CPD Points\n                                "),
        _c("span", [_vm._v("CATEGORY 1")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list__content" }, [
      _c("span", [
        _vm._v("CPD Points\n                                "),
        _c("span", [_vm._v("CATEGORY 2")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "list__content" }, [
      _c("span", [
        _vm._v("CPD Points\n                                "),
        _c("span", [_vm._v("CATEGORY 3")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "list__icon" }, [
      _c("i", { staticClass: "fal fa-ticket" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "list__icon" }, [
      _c("i", { staticClass: "fal fa-ticket" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }