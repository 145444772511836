<template>
    <li>
        <slot />
    </li>
</template>
<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
</script>