
import BaseComponent from '../BaseComponent';


export default class passwordReveal extends BaseComponent {

	// requires @babel/plugin-proposal-class-properties

	// get instance from the object
	el = this[Object.keys(this)[0]];
	viewToggle = this.el.nextElementSibling;


	init() {
		this.initPasswordReveal();
		
	}

	initPasswordReveal(){

	
	

		// check for correct mark up and bind function
		if(this.viewToggle.classList.contains('view-toggle')){
			this.viewToggle.addEventListener('click', this.changeState.bind(this))
		} else{
			console.log('The "password-reveal" element should have the view toggle element as its sibling')
		} 
		
	}
	changeState(){

		// toggle eye
		this.viewToggle.classList.toggle('fa-eye-slash');
		this.viewToggle.classList.toggle('fa-eye');

		// toggle input type
		if (this.el.getAttribute("type") == "password") {
			this.el.setAttribute("type", "text");

		} else {
			this.el.setAttribute("type", "password");
		};
	}

};

