
import Vue from 'Node/vue'
import Vuex from 'Node/vuex'
import axios from 'Node/axios';

import filterContent from 'SharedVuex/actions/filterContent'



Vue.use(Vuex)


// root state object.
// each Vuex instance is just a single state tree.
const state = {


	locations: [],
	categories:[],

	// is the page loading
	isLoading: true,
  isError : false,
	// have you filled all the rows
	// fullPage: false,
	// the total amount of cards displayed
	// totalCards: 0,
	filtered: false,

	selectedFilters:{
		keyword: '',
		location: '',
		category: '',
	},

  error: false,

  content: [],
  filteredContent: [],

}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {

  ...filterContent,

  // fetch the list of possible 'tags'
  async fetchCategories( {commit} ) {
  	const response = await axios.get('/json/category.json');
  	if (Array.isArray(response.data)) {

  		commit('setCategories', response.data);
  	}
  },

  async fetchLocations( {commit} ) {
  	const response = await axios.get('/json/locations.json');
  	if (Array.isArray(response.data)) {

  		commit('setLocations', response.data);
  	}
  },

  async fetchData({ commit } ) {

  	const response = await axios.get('/json/events-example.json');

    // check the environment and log dev mode to console
    if (webpackHotUpdate) {
      console.log('In Dev Mode');
    }

    if (response.status == 200) {

      commit('setContent', response.data.Items);

    } else {

      commit('setError', true);
    }
  },



  async addCategory( {state, commit} , value) {

  	commit('updateCategory', value)
  },



}


// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {

	setContent: (state, content) => { state.content = content },

	setCategories: (state, categories) => { state.categories = categories },
	setLocations: (state, locations) => { state.locations = locations },

	setFilteredContent: (state, content) => ( state.filteredContent = content),


  setSelectedFilters: (state, obj ) => { 

    //- pass through an object of filters
    //- eg; { keyword : 'Test', location : 'Locatation Name'}
    // loop over each of the object keys and update the state

    for (let key in obj ){

      state.selectedFilters[key] = obj[key]

    }
    

  },


  setFiltered: (state, bool) => (state.filtered = bool),
  setIsLoading: (state, bool) => (state.isLoading = bool),
  setError: (state, bool) => (state.error = bool),


  updateCategory(state, value) {

    state.selectedFilters.category.push(...value)

  },



}




// getters are functions

const getters = {

	// fullPage: state => state.totalCards % 4 === 0 ? true : false,
	allContent (state) { return state.content  },
	contentFiltered(state) { return  state.filteredContent},
	isLoading (state) { return state.isLoading },
  isError(state) { return state.isError },
  isFiltered (state) { return state.filtered },
  allCategories (state) { return state.categories },
  allLocations (state) { return state.locations },
  allSelectedFilters (state) { return state.selectedFilters },
  getKeyword (state) { return state.selectedFilters.keyword }

}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({

	state,
	getters,
	actions,
	mutations,

});
