
<template>

	<section id="EventsCards" class="container card-container">
		<div class="row">
			<div class="col-xs-12 line-heading">
				<h3>	{{  contentTotal   }} results </h3>
			</div>
		</div>
		<div class="row">

			<Loader v-if="isLoading" ></Loader>

			<Card v-if="!isLoading && !isError" v-for="item, index in events()" :key="index" :card="item" ></Card>

			<Error v-if="isError"/> 

		</div>
		
		<div class="row">
			<div class="col-xs-12">
				<nav class="pagination-container" role="navigation">
					<ul class="pagination">
						<li class="disabled">
							<a href="#" aria-label="Previous">
								<i class="far fa-long-arrow-alt-left" aria-hidden="true">
								</i>
							</a>
						</li>
						<li class="active">
							<a href="#"> 1 </a> 
						</li>
						<li>
							<a href="#"> 2 </a>
						</li>
						<li>
							<a href="#"> 3 </a>
						</li>
						<li>
							<a href="#"> 4 </a>
						</li>
						<li>
							<a href="#"> 5 </a>
						</li>
						<li>
							<a href="#" aria-label="Next">
								<i class="far fa-long-arrow-alt-right " aria-hidden="true"></i>
							</a>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</section>

</template>

<script>


import { mapGetters, mapActions, mapState, mapMutations } from 'Node/vuex'


import Loader from 'CustomVueComponents/utils/_loading/_loading.vue';
import Error from 'CustomVueComponents/utils/_error/_error.vue';
import Card from 'CustomVueComponents/_cards/_eventCard';


export default {

	components:{
		
		Loader,
		Error,
		Card,

	},

	methods:{
		...mapActions(['fetchLocations', 'fetchData']),
		...mapMutations(['setIsLoading']),
		updateFilters: function() {
			// console.log( this.allSelectedFilters.location )
		},

		events: function () {

			if(this.isFiltered){

				if (this.contentFiltered.length) {
					this.setIsLoading(false);
					return this.contentFiltered;

				} else{
					this.setIsLoading(false);
					return [];
				}
			} 

			this.setIsLoading(false);

			return this.allContent;
			

		},
		
		
	}, 
	data(){
		return{

		}
	},
  // computed: mapState({

  // 	tags: state => store.state.tags,

  // }),

  computed: {

  	...mapGetters( [ 'allContent', 'contentFiltered', 'isFiltered', 'isLoading', 'isError']),

  	contentTotal : function(){
  		if(this.isFiltered){

  			return this.contentFiltered.length
  		} else{
  			return this.allContent.length
  		}
  	}

  },
  mounted(){

  },
  created() {
  	this.fetchLocations();
  	this.fetchData();
  }


};

</script>