var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-3" }, [
    _c("div", { staticClass: "card card--resources" }, [
      _vm.card.Article_urlview
        ? _c("a", { attrs: { href: _vm.card.Article_urlview } }, [
            _c("div", { staticClass: "card__content" }, [
              _c("div", { staticClass: "card__block" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm.card.Article_whenposted
                  ? _c("div", { staticClass: "card__subheading" }, [
                      _c("span", { staticClass: "subheading" }, [
                        _vm._v(
                          _vm._s(_vm.card.Article_whenposted.split(",")[1])
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.card.Article_title
                  ? _c("div", { staticClass: "card__title" }, [
                      _c("h3", {
                        domProps: { innerHTML: _vm._s(_vm.card.Article_title) }
                      })
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _vm.card.Article_abstract
                ? _c("p", {
                    domProps: { innerHTML: _vm._s(_vm.card.Article_abstract) }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "card__info" }, [
                _c(
                  "ul",
                  [
                    _vm.card.Event_idcpdactivitytype === "1000000"
                      ? _c("li", { staticClass: "list__item cpd__item" }, [
                          _c(
                            "span",
                            { staticClass: "list__icon cpd-shape category-1" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.card.CPDCompetencies[0]
                                    .ArticleCPDCompetencyRel_points * 1
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "list__content" }, [
                            _c("span", [
                              _vm._v(
                                "CPD Points\n                                    "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.card.CPDCompetencies[0]
                                      .ArticleCPDCompetencyRel_name
                                  )
                                )
                              ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.Event_idcpdactivitytype === "1000001"
                      ? _c("li", { staticClass: "list__item cpd__item" }, [
                          _c(
                            "span",
                            { staticClass: "list__icon cpd-shape category-2" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.card.CPDCompetencies[0]
                                    .ArticleCPDCompetencyRel_points * 1
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "list__content" }, [
                            _c("span", [
                              _vm._v(
                                "CPD Points\n                                    "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.card.CPDCompetencies[0]
                                      .ArticleCPDCompetencyRel_name
                                  )
                                )
                              ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.card.Event_idcpdactivitytype === "1000002"
                      ? _c("li", { staticClass: "list__item cpd__item" }, [
                          _c(
                            "span",
                            { staticClass: "list__icon cpd-shape category-3" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.card.CPDCompetencies[0]
                                    .ArticleCPDCompetencyRel_points * 1
                                )
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "list__content" }, [
                            _c("span", [
                              _vm._v(
                                "CPD Points\n                                    "
                              ),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.card.CPDCompetencies[0]
                                      .ArticleCPDCompetencyRel_name
                                  )
                                )
                              ])
                            ])
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.card.Products, function(item) {
                      return _vm.card.Products.length
                        ? _c("li", { staticClass: "list__item" }, [
                            _vm._m(1, true),
                            _vm._v(" "),
                            _c("div", { staticClass: "list__content" }, [
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.Prices[1].Product_pricetaxincl) +
                                    "\n                                    "
                                ),
                                _c("span", [_vm._v("Members")])
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(item.Prices[0].Product_pricetaxincl) +
                                    "\n                                    "
                                ),
                                _c("span", [_vm._v("Regular")])
                              ])
                            ])
                          ])
                        : _vm._e()
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__type" }, [
      _c("i", { staticClass: "fal fa-file-alt" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "list__icon" }, [
      _c("i", { staticClass: "fal fa-shopping-cart" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }