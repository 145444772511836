
<template>


	<div id="EventFilters" class="card-filters">


		<form id="filter-form">

			<vFormGroup class="directory-filter">
				<vInput placeholder="Type Keyword" name="keyword-search"  v-model="keywordFilter" ></vInput>
				<vSelect :options="allLocations"  placeholder="All Regions" v-model="locationFilter"></vSelect>
			</vFormGroup>

			<div class="search-options-container">

			<vButton @click="toggleAdditionalFilters" role="button" class="btn btn-link"> {{ additionalFiltersOpen ? "Fewer" : "More" }} Search Options <i class="far fa-chevron-down"></i></vButton>

			<SmoothReflow :options="{ transitionEvent: { selector: '.additional-filters' } }">

				<div v-show="additionalFiltersOpen" class="additional-filters">

					<vFormGroup label="Level">
						<vSelect :options="allLevels" v-model="levelFilter" placeholder="All Levels" ></vSelect>
					</vFormGroup>

					<vFormGroup label="Type">
						<vSelect :options="allCategories" v-model="categoryFilter" placeholder="All Types" ></vSelect>
					</vFormGroup>

					<vFormGroup label="Date Listed">
						<vSelect :options="allDateRanges" v-model="dateRangeFilter" placeholder="All Dates"  ></vSelect>
					</vFormGroup>

				</div>

			</SmoothReflow>


			<vButton class="btn btn-default" @click="submit" type="submit">
				<i class="fas fa-search"></i>  
				Search
			</vButton>
			
			</div>

		</form>


	</div>



</template>
<script>


import { mapGetters, mapActions, mapState, mapMutations  } from 'Node/vuex'

import SmoothReflow from 'CustomVueComponents/utils/SmoothReflow';

import vFormGroup 	from 'CustomVueComponents/_forms/_formGroup';
import vInput 			from 'CustomVueComponents/_forms/_input';
import vButton 			from 'CustomVueComponents/_forms/_button';

import vSelect from 'Node/vue-select'


export default {

	components:{
		SmoothReflow,
		vFormGroup,
		vInput,
		vButton,
		vSelect,
	},
	mixins: [SmoothReflow],

	data : function() {

		return {

			keywordFilter 	: '',
			locationFilter 	: '',
			categoryFilter 	: '',
			levelFilter 		: '',
			dateRangeFilter : '',


			additionalFiltersOpen	: false,



		}
	},

	methods:{
		...mapActions(['fetchLocations', 'filterContent', 'fetchLevels', 'fetchDateRanges',  'fetchCategories' ]),
		...mapMutations(['setSelectedFilters']),
		// updateFilters: function() {
		// 	console.log( this.allSelectedFilters.location )
		// },
		toggleAdditionalFilters	: function(){
			event.preventDefault();
			this.additionalFiltersOpen ? this.additionalFiltersOpen  = false : this.additionalFiltersOpen  = true ;
		},

		submit : function(){

			// if the keyword filter is set then update the state
			

			this.setSelectedFilters(

			{ 
				keyword  : this.keywordFilter,
				location : this.locationFilter,
				category : this.categoryFilter,
				level 	 : this.levelFilter,
			}

			);

			this.filterContent('Job');
		},
	}, 



	computed: {
		...mapGetters([ 'allLocations' , 'allLevels', 'allDateRanges',  'allCategories' , 'allSelectedFilters' ]),

	},

	created() {

		this.fetchLevels();
		this.fetchLocations();
		this.fetchDateRanges();
		this.fetchCategories();

	}


};

</script>