var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-4 col-lg-3" }, [
    _c("div", { staticClass: "card card--awards" }, [
      _vm.card.CompanyProfile_urlview
        ? _c("a", { attrs: { href: _vm.card.CompanyProfile_urlview } }, [
            _c("div", { staticClass: "card__content" }, [
              _c("div", { staticClass: "card__block" }, [
                _vm._m(0),
                _vm._v(" "),
                _vm.card.CompanyProfile_createdwhen
                  ? _c("div", { staticClass: "card__subheading" }, [
                      _c("span", { staticClass: "subheading" }, [
                        _vm._v(_vm._s(_vm.getAwardYear(_vm.card)) + " Winner")
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.card.CompanyProfile_title
                  ? _c("div", { staticClass: "card__title" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.card.CompanyProfile_title))])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.card.CompanyProfile_abstract
                  ? _c("p", [_vm._v(_vm._s(_vm.card.CompanyProfile_abstract))])
                  : _vm._e()
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card__type" }, [
      _c("i", { staticClass: "fal fa-trophy" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }