var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-filters", attrs: { id: "EventFilters" } },
    [
      _c(
        "form",
        { attrs: { id: "filter-form" } },
        [
          _c(
            "vFormGroup",
            { staticClass: "directory-filter" },
            [
              _c("vInput", {
                attrs: { placeholder: "Type Keyword", name: "keyword-search" },
                model: {
                  value: _vm.keywordFilter,
                  callback: function($$v) {
                    _vm.keywordFilter = $$v
                  },
                  expression: "keywordFilter"
                }
              }),
              _vm._v(" "),
              _c("vSelect", {
                attrs: {
                  options: _vm.allLocations,
                  placeholder: "All Regions"
                },
                model: {
                  value: _vm.locationFilter,
                  callback: function($$v) {
                    _vm.locationFilter = $$v
                  },
                  expression: "locationFilter"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-options-container" },
            [
              _c(
                "vButton",
                {
                  staticClass: "btn btn-link",
                  attrs: { role: "button" },
                  on: { click: _vm.toggleAdditionalFilters }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.additionalFiltersOpen ? "Fewer" : "More") +
                      " Search Options "
                  ),
                  _c("i", { staticClass: "far fa-chevron-down" })
                ]
              ),
              _vm._v(" "),
              _c(
                "SmoothReflow",
                {
                  attrs: {
                    options: {
                      transitionEvent: { selector: ".additional-filters" }
                    }
                  }
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.additionalFiltersOpen,
                          expression: "additionalFiltersOpen"
                        }
                      ],
                      staticClass: "additional-filters"
                    },
                    [
                      _c(
                        "vFormGroup",
                        { attrs: { label: "Level" } },
                        [
                          _c("vSelect", {
                            attrs: {
                              options: _vm.allLevels,
                              placeholder: "All Levels"
                            },
                            model: {
                              value: _vm.levelFilter,
                              callback: function($$v) {
                                _vm.levelFilter = $$v
                              },
                              expression: "levelFilter"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vFormGroup",
                        { attrs: { label: "Type" } },
                        [
                          _c("vSelect", {
                            attrs: {
                              options: _vm.allCategories,
                              placeholder: "All Types"
                            },
                            model: {
                              value: _vm.categoryFilter,
                              callback: function($$v) {
                                _vm.categoryFilter = $$v
                              },
                              expression: "categoryFilter"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "vFormGroup",
                        { attrs: { label: "Date Listed" } },
                        [
                          _c("vSelect", {
                            attrs: {
                              options: _vm.allDateRanges,
                              placeholder: "All Dates"
                            },
                            model: {
                              value: _vm.dateRangeFilter,
                              callback: function($$v) {
                                _vm.dateRangeFilter = $$v
                              },
                              expression: "dateRangeFilter"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "vButton",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "submit" },
                  on: { click: _vm.submit }
                },
                [
                  _c("i", { staticClass: "fas fa-search" }),
                  _vm._v("  \n\t\t\tSearch\n\t\t")
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }