var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "container section", attrs: { id: "JobCards" } },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12" },
          [
            _vm.isLoading ? _c("Loader") : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.jobs(), function(item, index) {
              return !_vm.isLoading && !_vm.isError
                ? _c("Card", { key: index, attrs: { card: item } })
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.isError ? _c("Error") : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-xs-12" }, [
        _c(
          "nav",
          {
            staticClass: "pagination-container",
            attrs: { role: "navigation" }
          },
          [
            _c("ul", { staticClass: "pagination" }, [
              _c("li", { staticClass: "disabled" }, [
                _c("a", { attrs: { href: "#", "aria-label": "Previous" } }, [
                  _c("i", {
                    staticClass: "far fa-long-arrow-alt-left",
                    attrs: { "aria-hidden": "true" }
                  })
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "active" }, [
                _c("a", { attrs: { href: "#" } }, [_vm._v(" 1 ")])
              ]),
              _vm._v(" "),
              _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v(" 2 ")])]),
              _vm._v(" "),
              _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v(" 3 ")])]),
              _vm._v(" "),
              _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v(" 4 ")])]),
              _vm._v(" "),
              _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v(" 5 ")])]),
              _vm._v(" "),
              _c("li", [
                _c("a", { attrs: { href: "#", "aria-label": "Next" } }, [
                  _c("i", {
                    staticClass: "far fa-long-arrow-alt-right ",
                    attrs: { "aria-hidden": "true" }
                  })
                ])
              ])
            ])
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }