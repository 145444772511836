<template>
    <div class="col-md-6">
        <div class="registry-item">
            <div class="registry-item__text">
                <h3 class="registry-item__title">{{card.PersonProfile_title}}</h3>

                <p class="registry-item__detail" v-if="card.Company_name">{{card.Company_name}}</p>
                <p class="registry-item__detail" v-if="card.MembershipClass_name">{{card.MembershipClass_name}}</p>
                <p class="registry-item__detail" v-if="card.Groups.length" v-for="item in card.Groups">
                    <span v-if="item.PersonGroup_name === 'Fellows'">Membership Type: Fellow</span>
                    <span v-else-if="item.PersonGroup_name === 'Supporter'">Membership Type: Supporter</span>
                </p>
            </div>
            <div class="registry-item__badges" v-if="card.Groups.length">
                <div class="member-type" v-for="item in card.Groups">
                    <span v-if="item.PersonGroup_name === 'Fellows'" class="member-type__symbol member-type__symbol--superstar">
                        <img src="/themes/portal-2021/images/nzpi-fellow-logo.png">
                    </span>
                    <span v-else-if="item.PersonGroup_name === 'CPD 2019'" class="member-type__symbol member-type__symbol--fellow">
                        <img src="/themes/portal-2021/images/CPD_2019.png">
                    </span>
                    <span v-else-if="item.PersonGroup_name === 'CPD 2020'" class="member-type__symbol member-type__symbol--international">
                        <img src="/themes/portal-2021/images/CPD_2020.png">
                    </span>
                    <span v-else-if="item.PersonGroup_name === 'CPD 2021'" class="member-type__symbol member-type__symbol--1">
                        <img src="/themes/portal-2021/images/CPD_2021.png">
                    </span>
                    <span v-else-if="item.PersonGroup_name === 'Supporter'" class="member-type__symbol member-type__symbol--2">
                        <img src="/themes/portal-2021/images/nzpi-supporter-logo1.png">
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

    name: 'PeopleCard',

    props: {

        card: {},

    },
    methods: {
        // getCPDPoints: (card) => {
        //     //console.log(card);

        //     let cpd = card.CPDCompetencies;
        //     let sum = 0;
        //     let category = [];
        //     for (let i = 0; i < cpd.length; i++) {
        //         if (cpd[i].EventCPDCompetencyRel_points*1) {
        //             sum += cpd[i].EventCPDCompetencyRel_points * 1;
        //             category.push(cpd[i].EventCPDCompetencyRel_name);
        //         }
        //     }
        //     console.log(sum);
        //     return { sum: sum};

        // },
    },
    data: function(){
        return{
        }
    },
    
    computed: {
    },

};
</script>