

<template>

	<transition name="fade-out">
		<h1>Sorry but there was an error retrieving the data</h1>
	</transition>

</template>




<script>


export default {

	name:'Error',

	data(){

	},

};

</script>