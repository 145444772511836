
import BaseComponent from './BaseComponent';

export default class countDownTimer extends BaseComponent {

    init() {
    this.initCountDown();
  }

  initCountDown(){
    // Set the date we're counting down to
    // date must be this format "Jan 5, 2021 15:37:25"
     let countDownDate
    if ( this.element.getAttribute('data-time') ){
      countDownDate =  new Date( this.element.getAttribute('data-time') ).getTime();
    } 
   
    setTimeout( ()=> { 
      this.element.classList.add('active');
    }, 1000);
    // Update the count down every 1 second
    let interval =  setInterval( ()=>{ this.timerFunction(this.element, countDownDate) } , 1000) 
    // If the count down is finished, write some text

  }
  timerFunction(el, date){
    console.log('test')
    // Get today's date and time
    let now = new Date().getTime();
    // Find the distance between now and the count down date
    let distance = date - now;

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

     // Display the result in the element 
    el.innerHTML = "<span class='days' >" + days + " <small>Days</small> </span> <span class='hours' > " + hours + " <small> hours </small> </span><span class='minutes' > "
    + minutes + " <small>minutes</small> </span> <span class='seconds' > " + seconds + " <small>seconds</small> </span> ";

      if (distance < 0) {
      clearInterval(interval);
      el.innerHTML = "Ready! Please refresh the page!";
    }

  }



};