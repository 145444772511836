var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "pagination-container", attrs: { role: "navigation" } },
    [
      _c(
        "ul",
        { staticClass: "pagination" },
        [
          _c(
            "BaseButton",
            {
              class: { disabled: _vm.isPreviousButtonDisabled },
              nativeOn: {
                click: function($event) {
                  return _vm.previousPage($event)
                }
              }
            },
            [
              _c("a", { attrs: { "aria-label": "Previous" } }, [
                _c("i", {
                  staticClass: "far fa-long-arrow-alt-left",
                  attrs: { "aria-hidden": "true" }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.paginationTriggers, function(paginationTrigger) {
            return _c("BasePaginationTrigger", {
              key: paginationTrigger,
              class: { active: paginationTrigger === _vm.activePage },
              attrs: { pageNumber: paginationTrigger },
              on: { loadPage: _vm.onLoadPage }
            })
          }),
          _vm._v(" "),
          _c(
            "BaseButton",
            {
              class: { disabled: _vm.isNextButtonDisabled },
              nativeOn: {
                click: function($event) {
                  return _vm.nextPage($event)
                }
              }
            },
            [
              _c("a", { attrs: { "aria-label": "Next" } }, [
                _c("i", {
                  staticClass: "far fa-long-arrow-alt-right",
                  attrs: { "aria-hidden": "true" }
                })
              ])
            ]
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }