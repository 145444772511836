var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade-out" } }, [
    _c("div", { staticClass: "loading-container" }, [
      _c("i", { staticClass: "far fa-spinner loading__spinner" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }