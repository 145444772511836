// Event Filtering

// import Filters from './components/EventFilters.vue';
import Cards from './components/EventCards.vue';
import store from './store.js'


export const EventModules = {

	store : store,

	components : {
		// '#EventFilters' : Filters,
		'#EventCards'	: Cards,
		
	},


};
