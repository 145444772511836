<template>
    <div class="job-listing">
        <div class="content">
        	<a :href="card.JobListing_urlview">
        		<span class="date-listed" v-if="card.JobListing_whenposted">
        			{{card.JobListing_whenposted.split(',')[1]}}
        		</span>

                <h3>{{card.JobListing_title}}</h3>

                <p>{{card.JobListing_advertisername}}</p>

                <div class="extra-info">
                	<span class="extra-info__item" v-if="card.JobListing_location">
                		<i class="fal fa-map-marker-alt"></i>

                        <div class="extra-info__details">
                            <div class="extra-info__detail">
                                {{card.JobListing_location}}
                            </div>
                            <div class="extra-info__type">
                                Region
                            </div>
                        </div>

                    </span>
                    <span class="extra-info__item" v-if="card.JobListing_category">
                        <i class="fal fa-users"></i>
                        <div class="extra-info__details">
                            <div class="extra-info__detail">
                               {{card.JobListing_category}}
                            </div>
                            <div class="extra-info__type">
                                Level
                            </div>
                        </div>
                    </span> 
                    <span class="extra-info__item" v-if="card.JobListing_type">
                        <i class="fal fa-clock"></i>
                        <div class="extra-info__details">
                            <div class="extra-info__detail">
                                {{card.JobListing_type}}
                            </div>
                            <div class="extra-info__type">
                                Type
                            </div>
                        </div>
                    </span>
                </div>
            </a>
        </div>
        
        <div class="job-logo">
            <div class="card__img img-container" v-if="card.Images.length">
                <img :src="card.Images[0].Image_urlview" style="object-fit: contain;">
            </div>
        </div>
    </div>
    
</template>
<script>
import { mapGetters, mapActions, mapState } from 'Node/vuex'


export default {
    name: "JobCard",

    props: {
        card: {},
    },


    data: function() {
        return {


        }
    },
    methods: {
        formatDate(date) {
            // format the date 

            const d = new Date(date)
            const year = d.getFullYear();
            const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
            const month = d.getMonth();
            const formatedMonth = months[month]

            const day = d.getDate();
            return day + ' ' + formatedMonth + ' ' + year
        }

    },


};
</script>