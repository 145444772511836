// News Filtering


import store from './store.js'
import Filters from './components/NewsFilters.vue';
import Cards from './components/NewsCards.vue';

export const NewsModules = {

	store : store,

	components : {

		//
		'#NewsFilters' : Filters,
		'#NewsCards'	: Cards,
		
	},


};
