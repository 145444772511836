

import store from './store.js'


export const CpdHistoryModules = {

	store : store,

	components : {

		'#CpdGraph'	:  () => import(
			/* webpackChunkName: "CpdGraph" */
			/* webpackMode: 'lazy'  */
			'./components/CpdGraph.vue'
			),
		'#CpdHistoryTable'	:  () => import(
			/* webpackChunkName: "CpdHistoryTable" */
			/* webpackMode: 'lazy'  */
			'./components/CpdHistoryTable.vue'
			)

	},

};
