<template>
    <div class="col-xs-12 col-md-4">
        <div class="card card--consultant">
            <div class="card__img" v-if="card.Images.length">
                <img :src="card.Images[0].Image_urlview" >
                <div class="nzpi-supporter" v-if="card.CompanyGroup_name === 'NZPI Supporters'">
                    <div class="nzpi-supporter__logo">
                        <img src="/images/nzpi-supporter-logo.png">
                    </div>
                    <span class="nzpi-supporter__text">NZPI <br> Supporter</span>
                </div>
            </div>
            <div class="card__img" v-else>
                <i class="fal fa-building" style="font-size: 50px;"></i>
                <div class="nzpi-supporter" v-if="card.CompanyGroup_name === 'NZPI Supporters'">
                    <div class="nzpi-supporter__logo">
                        <img src="/images/nzpi-supporter-logo.png">
                    </div>
                    <span class="nzpi-supporter__text">NZPI <br> Supporter</span>
                </div>
            </div>

            <div class="card__content">
                <div class="card__block">
                    <h4 class="card__title">{{card.CompanyProfile_title}}</h4>
                </div>
                <ul class="card__info">
                    <li class="card__info-item" v-if="card.Locations.length">
                        <i class="fal fa-map-marker-alt"></i>
                        <span class="card__info-text">x{{card.Locations.length}} Locations</span>
                    </li>
                    <li class="card__info-item" v-if="card.Company_mail">
                        <i class="fal fa-envelope"></i>
                        <span class="card__info-text">
                            <a :href="`mailto:${card.Company_mail}`">{{card.Company_mail}}</a>
                        </span>
                    </li>
                    <li class="card__info-item" v-if="card.Company_website">
                        <i class="fal fa-link"></i>
                        <span class="card__info-text">
                            <a :href="`//${card.Company_website}`">{{card.Company_website}}</a>
                        </span>
                    </li>
                </ul>
                <a v-if="card.CompanyProfile_urlview" class="more" :href="card.CompanyProfile_urlview">View profile</a>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'Node/vuex';


export default {
    name: "ConsultantCard",

    props: {
        card: {},
    },

    data: function() {
        return {


        }
    },

    methods: {


    },



    computed: {


    },

    mounted() {


    },

};
</script>