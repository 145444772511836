
import BaseComponent from './BaseComponent';

export default class scrollPast extends BaseComponent {

	init() {
		this.initScrollPast();
	}

	initScrollPast() {

		//- must use strings / class names or id's when calling the arguments,
		//- not jquery objects 
		// eg; scrollDownTrigger('.arrow-btn', '.hero')
		let $els = document.querySelector(this.element),
		$parent = $el.closest('.scrollPast--parent');

		$els.forEach((el) => { 
			el.addEventListener('click', ()=>{

				
			} );

		});
	}


	
}

