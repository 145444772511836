

<template>

	<transition name="fade-out">
		<div class="loading-container">
			<i class="far fa-spinner loading__spinner"></i>
		</div>
	</transition>

</template>



<style lang="scss">

@import './_loading.scss'; 

</style>



<script>


export default {

	name:'Loader',

	data(){
		return{
			loading : false,
		}
	},

};

</script>