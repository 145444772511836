var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      attrs: {
        type: _vm.type,
        "aria-label": _vm.label,
        id: _vm.id,
        disabled: _vm.disabled
      },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.handleClick($event)
        }
      }
    },
    [_vm._t("default", [_vm._v("Button")])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }