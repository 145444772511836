<template>
    <li><a @click="onClick">{{ pageNumber }}</a></li>
</template>
<script>
export default {
    props: {
        pageNumber: {
            type: Number,
            required: true
        }
    },

    methods: {
        onClick() {
            this.$emit("loadPage", this.pageNumber);
        }
    }
};
</script>