import Vue from 'Node/vue'
import Vuex from 'Node/vuex'
import axios from 'Node/axios';



Vue.use(Vuex)


// root state object.
// each Vuex instance is just a single state tree.
const state = {

	

	// is the page loading
	isLoading: true,
	// have you filled all the rows
	// fullPage: false,
	// the total amount of cards displayed
	// totalCards: 0,


  error: false,

  content: [],
  sortedContent: [],

}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {




  async fetchData({ commit } ) {

  	const response = await axios.get('/json/exampleCpdHistory.json');

    // check the environment and log dev mode to console
    if (webpackHotUpdate) {
      console.log('In Dev Mode');
    }

    if (response.status == 200) {

      commit('setContent', response.data.Items);

    } else {

      commit('setError', true);
    }
  },


  async sortContent({commit}){

    if( numeric ){

    } else if ( alphabet ){

    } else if (date){

    } else {
      
    }


  }


}


// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {

	setContent: (state, content) => { state.content = content },
	setSortedContent: (state, events) => ( state.filteredEvents = events),
  setIsLoading: (state, bool) => (state.isLoading = bool),
  setError: (state, bool) => (state.error = bool),




}




// getters are functions

const getters = {

	// fullPage: state => state.totalCards % 4 === 0 ? true : false,

  sortList (state) { return state.sortList },
  allContent (state) { return state.content  },
  isLoading (state) { return state.isLoading },
  isError(state) { return state.error },

}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({

	state,
	getters,
	actions,
	mutations,

});
