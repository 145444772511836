<template>
	
	<button  @click.prevent="handleClick" :type="type" :aria-label="label" :id="id" :disabled="disabled">
		<slot>Button</slot>
	</button>

</template>



<script>
export default {
	name: "vButton",
	inheritAttrs: false,
	props:{
		id:{
			type:	String,
			default: null,
		},
		label:{
			type:	String,
			default: 'button',
		},
		btnClass:{
			type:	String,
			default: 'btn btn-default'
		},
		type:{
			type:	String,
			default: 'button',
		},
		disabled:{
			type:	Boolean,
			default: false,
		},
		onClick: {
			type: Function,
	
		}
	},
	methods:{
		handleClick() {
    	this.$emit('click')
    }
	}
};

</script>