
<template>


	<div id="EventFilters" class="card-filters">


		<form id="filter-form">

			<vFormGroup class="directory-filter">
				<vInput placeholder="Type Keyword" name="keyword-search"  v-model="keywordFilter" ></vInput>
				<vSelect :options="allCategories" v-model="categoryFilter" placeholder="All Categories" ></vSelect>
			</vFormGroup>

			<div class="search-options-container">

			<vButton @click="toggleAdditionalFilters" role="button" class="btn btn-link"> {{ additionalFiltersOpen ? "Fewer" : "More" }} Search Options <i class="far fa-chevron-down"></i></vButton>




			<vButton class="btn btn-default" @click="submit" type="submit">
				<i class="fas fa-search"></i>  
				Search
			</vButton>
			

		</div>
		</form>


	</div>



</template>
<script>


import { mapGetters, mapActions, mapState, mapMutations  } from 'Node/vuex'

import SmoothReflow from 'CustomVueComponents/utils/SmoothReflow';

import vFormGroup 	from 'CustomVueComponents/_forms/_formGroup';
import vInput 			from 'CustomVueComponents/_forms/_input';
import vButton 			from 'CustomVueComponents/_forms/_button';

import vSelect from 'Node/vue-select'


export default {

	components:{
		SmoothReflow,
		vFormGroup,
		vInput,
		vButton,
		vSelect,
	},
	mixins: [SmoothReflow],

	data : function() {

		return {

			keywordFilter 	: '',
			locationFilter 	: '',
			categoryFilter 	: '',

			additionalFiltersOpen	: false,



		}
	},

	methods:{
		...mapActions(['fetchLocations', 'filterContent', 'fetchCategories' ]),
		...mapMutations(['setSelectedFilters']),
		// updateFilters: function() {
		// 	console.log( this.allSelectedFilters.location )
		// },
		toggleAdditionalFilters	: function(){
			event.preventDefault();
			this.additionalFiltersOpen ? this.additionalFiltersOpen  = false : this.additionalFiltersOpen  = true ;
		},

		submit : function(){

			// if the keyword filter is set then update the state
			

			this.setSelectedFilters(

			{ 
				keyword : this.keywordFilter,
				location : this.locationFilter,
				category: this.categoryFilter,
			}

			);

			this.filterContent('Event');
		},
	}, 



	computed: {
		...mapGetters( ['allLocations' , 'allCategories' , 'allSelectedFilters']),

	},

	created() {
		this.fetchLocations();
		this.fetchCategories();
	}


};

</script>