var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-input" },
    [
      _vm.label
        ? _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.label))])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group" },
        [
          _c("input", {
            class: [_vm.inputClass ? _vm.inputClass : "form-control"],
            attrs: {
              id: _vm.id,
              placeholder: _vm.placeholder,
              type: [_vm.type ? _vm.type : "text"],
              name: _vm.name,
              "aria-requried": _vm.required,
              required: _vm.required
            },
            domProps: { value: _vm.value },
            on: {
              input: function($event) {
                return _vm.$emit("input", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("help-text")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }