<template>



	<div class="form-group">
		<label v-if="label">{{ label }}</label>

		<span v-if="inputDetail" v-html="inputDetail" ></span>

		<slot/>

		<span v-if="inputCaption" v-html="inputCaption" ></span>


	</div>


</template>

<script>


export default {
	name: "vFormGroup",


	props:{
		// error prop must be passed using :validator="$v.VuilidateFiledName"
		label: String,
		inputDetail: '',
		inputCaption: '',
		error :{
			type: Object,
		}
	}

};

</script>