import Vue from 'Node/vue';
import axios from 'Node/axios';



export default class VueLoader {

  constructor(modules) {
    this.modules = modules;
  }

  load() { 
    //--  get the object containing the store and an object of modules to include
    //--  and render
    let modules = this.modules;
    //--  tidy up the vars so you dont have to call the object.key
    let store = modules.store
    let components = modules.components

    //-- check the store is set if you want
    // console.log( store );


    //-- for each of the components listed in the components object 
    //-- get the key ( the div ID  ) to render the component in
    //-- and the vue element to load 
    for (let selector in components) {

      let objects = document.querySelectorAll(selector);

      Array.from(objects).forEach((element) => {

        let component = components[selector];
          
        // render it all out
        new Vue({
          store,
          render: r => r( component )
        }).$mount(element);
 

      });
    }
  }
}