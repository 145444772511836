// SmoothReflow.vue
<template>
    <component :is="tag" class="smooth-container">
        <slot/>
    </component>
</template>

<script>
import smoothReflow from 'Node/vue-smooth-reflow'
export default {
    name: 'SmoothReflow',
    mixins: [smoothReflow],
    props: {
        tag: {
            type: String,
            default: 'div'
        },
        options: Object,
    },
    mounted () {
        this.$smoothReflow(this.options)
    }
};
</script>