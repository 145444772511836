var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-filters", attrs: { id: "EventFilters" } },
    [
      _c(
        "form",
        { attrs: { id: "filter-form" } },
        [
          _c(
            "vFormGroup",
            { staticClass: "directory-filter" },
            [
              _c("vInput", {
                attrs: { placeholder: "Type Keyword", name: "keyword-search" },
                model: {
                  value: _vm.keywordFilter,
                  callback: function($$v) {
                    _vm.keywordFilter = $$v
                  },
                  expression: "keywordFilter"
                }
              }),
              _vm._v(" "),
              _c("vSelect", {
                attrs: {
                  options: _vm.allCategories,
                  placeholder: "All Categories"
                },
                model: {
                  value: _vm.categoryFilter,
                  callback: function($$v) {
                    _vm.categoryFilter = $$v
                  },
                  expression: "categoryFilter"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-options-container" },
            [
              _c(
                "vButton",
                {
                  staticClass: "btn btn-link",
                  attrs: { role: "button" },
                  on: { click: _vm.toggleAdditionalFilters }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.additionalFiltersOpen ? "Fewer" : "More") +
                      " Search Options "
                  ),
                  _c("i", { staticClass: "far fa-chevron-down" })
                ]
              ),
              _vm._v(" "),
              _c(
                "vButton",
                {
                  staticClass: "btn btn-default",
                  attrs: { type: "submit" },
                  on: { click: _vm.submit }
                },
                [
                  _c("i", { staticClass: "fas fa-search" }),
                  _vm._v("  \n\t\t\tSearch\n\t\t")
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }